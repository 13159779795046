/*
  * Global heading CSS styling
*/

h1 {
    text-align: left;
    font-weight: bold;
    color: var(--nav_bg);
}

.h2 {
    font-size: 1.75em;
    font-weight: bold;
    letter-spacing: 1px;
}

.h3 {
    font-size: 1.25rem;
    line-height: 1.5rem;
}

.sub-description {
    font-size: 2em;
    line-height: 1.4em;
}

@media screen and (max-width: 992px) {
    h1 {
        text-align: center;
    }

    .h2 {
        font-size: 2.5em;
    }
    
    .sub-description {
        font-size: 1.5em;
    }    
}