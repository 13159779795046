.loading_icon {
    display: inline-block;
    animation: spinningLoading 3s cubic-bezier(0.57, 0.08, 0.44, 0.95) infinite alternate;
    -webkit-animation: spinningLoading 3s cubic-bezier(0.57, 0.08, 0.44, 0.95) infinite alternate;
}

@keyframes spinningLoading {
    to {
        transform: rotate(-1080deg);
    }
}
@-webkit-keyframes spinningLoading {
    to {
        transform: rotate(-1080deg);
    }
}