.cart_alert {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  width: 100%;
  max-width: 400px;
  margin: auto;
  color: white;
  z-index: 1056;
  animation: showAlert 3s ease forwards;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
}

.alert_success {
  background-color: var(--verde);
}

.alert_delete {
  background-color: var(--vermelho);
}

.alert_show {
  display: inline-block;
}

@keyframes showAlert {
  0% {
    transform: translateY(-100%);
  }
  13% {
    transform: translateY(0);
  }
  87% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}
