.loading_shield {
    display: flex;
    width: 100%;
    height: 100%;
    position: fixed;
    flex-direction: column;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: var(--nav_bg);
}

.loading_shield.hide {
    animation: hideLoading .5s ease forwards;
    -webkit-animation: hideLoading .5s ease forwards;
}

@keyframes hideLoading {
    0% {
        opacity: 1;
    }
    99.9% {
        opacity: 0;
        transform: scale(1);
        z-index: 100;
    }
    100% {
        opacity: 0;
        transform: scale(0);
        z-index: -100;
    }
}

.loading_text {
    flex-shrink: 0;
    height: auto;
    overflow: auto;
    color: rgba(255, 255, 255, 0.7);
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 2px;
    height: auto;
    overflow: auto;
}

.logo {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.logo img {
    max-width: 450px;
    width: 60%;
}

.loading_icon {
    display: inline-block;
    animation: spinningLoading 3s cubic-bezier(0.57, 0.08, 0.44, 0.95) infinite alternate;
    -webkit-animation: spinningLoading 3s cubic-bezier(0.57, 0.08, 0.44, 0.95) infinite alternate;
}

@keyframes spinningLoading {
    to {
        transform: rotate(-1080deg);
    }
}
@-webkit-keyframes spinningLoading {
    to {
        transform: rotate(-1080deg);
    }
}