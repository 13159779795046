.action {
    justify-content: end;
    font-size: 16px;
}

.border_2px {
    border-width: 2px !important;
}

.disable_action {
    color: var(--bs-gray-500) !important;
    cursor: no-drop;
}

@media screen and (max-width: 992px) {
    .action {
        justify-content: space-between;
    }
}