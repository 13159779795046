.modal :global(.modal-body) {
  padding: 0;
}

@media screen and (min-width: 993px) {
  .modal :global(.modal-header) {
    display: none !important;
  }

  .button {
    cursor: pointer;
    color: var(--bs-gray-600);
  }

  .button:hover {
    background-color: var(--bs-gray-300);
  }

  .active {
    background-color: var(--verde) !important;
    color: white !important;
  }

  .nav {
    background-color: var(--bs-gray-200);
  }
}

@media screen and (max-width: 992px) {
  .button {
    display: inline-block;
    cursor: pointer;
    padding-bottom: 0.25rem;
    color: var(--bs-gray-600);
  }

  .button:hover {
    border-bottom: 2px solid var(--bs-gray-600);
  }

  .active {
    border-bottom: 2px solid var(--verde);
    color: var(--verde);
  }
}
