.button-bold {
    font-weight: bold;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    border-width: 2px;
    border-radius: 2rem;
}

.btn-widget {
    border-width: 2px !important;
    border-color: var(--bs-gray-200) !important;
}