@media screen and (max-width: 992px){
    .search {
        position: fixed;
        top: 0;
        width: 100%;
        transition: all ease .5s;
        background-color: white;
        border-bottom: 1px solid #eee;
        transform: translateY(-10px);
        z-index: 3;
    }

    .search :global(.search-input){
        padding: .5rem !important;
        border: none !important;
    }

    .show {
        transform: translateY(48px);
    }

    .search_button {
        position: fixed;
        top: 45px;
        right: 5px;
        display: inline-block;
        padding: .5rem .75rem;
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        background-color: white;
        border: 1px solid #eee;
        color: var(--verde);
        z-index: 2;
    }
}