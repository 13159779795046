.logo_menu {
  max-width: 100px;
}

@media screen and (max-width: 992px) {
  .logo_menu {
    max-width: unset;
    height: 60px;
  }

  .nav {
    width: 100%;
    transform: translateX(100%);
    border-radius: 0 !important;
    transition: transform ease 0.5s;
    -webkit-transition: transform ease 0.5s;
  }
  .nav_show {
    transform: translateX(0);
  }
  .nav_border_bottom {
    width: 100%;
    height: 3px;
    background: linear-gradient(to right, red, red, white, white, green, green);
  }
}

.nav_mobile {
  position: fixed;
  bottom: 0;
  background-color: white;
  height: 50px;
  z-index: 3;
}
