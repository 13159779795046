.nav_item,
.nav_item:hover {
    text-decoration: none;
    color: inherit;    
}

.active {
    color: var(--verde);
}

.btn {
    border-width: 2px;
    font-weight: bold;
    border-color: var(--bs-gray-200);
}

.active .btn,
.btn:hover {
    border-color: var(--verde);
    background-color: var(--verde);
    color: white;
}