.subgroup {
    margin-left: -1.5rem!important;
    margin-right: -1.5rem!important;
}

.subgroup_header {
    background-color: #ecffe8;
    position: sticky;
    top: -1px;
    z-index: 2;
}

.subgroup_option:hover {
    background-color: #f5f5f5;
}

.subgroup_option_photo {
    max-width: 70px;
    max-height: 70px;
}

.subgroup_checkbox_radio {
    position: relative;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    overflow: hidden;
    background-color: var(--bs-gray-300);
    cursor: pointer;
}

.subgroup_checkbox_radio div {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    border-radius: 100%;
    background-color: var(--verde);
    transform: scale(0);
    transition: all ease .1s;
}

.subgroup_checkbox_radio:global(.checked) div {
    transform: scale(1);
}

.disable_action {
    color: var(--bs-gray-500) !important;
    cursor: no-drop;
}

.subgroup :global(.required-tag) {
    transition: background-color ease .5s;
}

.subgroup:global(.required) :global(.required-tag) {
    background-color: rgba(var(--bs-danger-rgb),var(--bs-bg-opacity))!important;
    animation: popUpRequired ease 1s forwards;
}

@keyframes popUpRequired {
    0% {transform: scale(1);}
    50% {transform: scale(1.2);}
    100% {transform: scale(1);}
}