.card {
    border-width: 1px;
    border-style: solid;
    border-color: #eee;
    cursor: pointer;
    transition: all ease .2s;
    height: 100%;
    box-shadow: 0 1px 4px rgba(0,0,0,.05);
    min-height: 180px;
}

.card:hover {
    border-color: #b9b9b9;
}

@media screen and (max-width: 992px){
    .card {
        border-width: 0;
        border-bottom-width: 1px; 
        box-shadow: none;
        border-radius: 0 !important;
        min-height: unset;
    }
}