.nav_mobile {
    position: fixed;
    width: 100%;
    top: 0;
    background: white;
    z-index: 4;
    border-bottom: 1px solid #e6e6e6;
    height: 50px;
}

.nav_outer {
  overflow-x: scroll;
  scroll-behavior: smooth;
}

.nav_outer::-webkit-scrollbar {
    display: none;
}

.nav_tab {
  white-space: nowrap;
  text-align: center;
}

.nav_tab .nav_link {
  display: inline-block;
  height: 50px;
  padding: .80rem;
  font-size: .9em;
  margin: 0 0.5rem;
  color: unset;
  letter-spacing: 1px;
  text-decoration: none;
  opacity: 0.6;
  transition: opacity ease 0.3s;
  font-weight: bold;
}

.nav_tab .nav_link:hover {
  opacity: 1;
}

.nav_tab .nav_link:global(.active) {
  opacity: 1;
  color: var(--verde);
  position: relative;
}

.nav_tab .nav_link:global(.active)::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 4px;
  bottom: 0;
  left: 0;
  background-color: var(--verde);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  transform: translateY(1px);
  z-index: 3;
}
