.search_list {
  position: relative;
}

@media screen and (max-width: 996px) {
  .search_mobile {
    position: sticky;
    z-index: 1;
    top: 0;
    padding: 0.5rem 1rem;
    background-color: var(--bs-light);
  }
}

.icon,
.cancel {
  position: absolute;
  font-size: 1.1em;
  top: calc(0.7rem + 1px);
}

.icon {
  left: 12px;
}

.cancel {
  right: 13px;
  color: var(--bs-gray-600);
  top: calc(0.7rem - 2px);
  cursor: pointer;
  padding: 3px;
}
