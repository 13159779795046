.button {
    cursor: pointer;
    padding-bottom: .75rem;
    color: var(--bs-gray-600);
}

.button:hover {
    border-bottom: 2px solid var(--bs-gray-600);
}

.button.active {
    border-bottom: 2px solid var(--verde);
    color: var(--verde);
}