@media screen and (min-width: 993px) {
  .modal_dialog:global(.modal-xl) {
    max-width: 1200px !important;
  }

  .photo {
    max-width: 100%;
    max-height: 100%;
  }

  .modal_content {
    overflow: hidden;
  }

  .modal_body {
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    height: 100%;
  }

  .body_scrolling {
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .body_scrolling::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  .body_scrolling::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #dcdcdc;
  }

  .body_scrolling::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: transparent;
  }
}

@media screen and (max-width: 992px) {
  .modal_content {
    height: 100%;
    overflow: hidden;
    border-radius: 0;
  }

  .modal_content :global(.modal-body) {
    overflow-y: auto;
    max-height: unset;
  }

  .photo_mobile {
    max-height: 280px;
    font-size: 0;
    overflow: hidden;
    align-items: center;
    display: flex;
  }

  .photo {
    width: 100%;
    max-height: unset;
  }
}
