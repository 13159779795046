.modal_dialog {
  max-height: 80vh;
  overflow: hidden;
}

.modal_dialog:global(.modal-xl) {
  max-width: 1200px !important;
}

.modal_content {
  overflow: hidden;
}

.modal_body {
  display: flex;
  flex-direction: column;
  height: 80vh;
}

.body_action {
}

.body_scrolling {
  flex: 1;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media only screen and (min-width: 993px) {
  .body_header {
    border-bottom: 0 !important;
  }

  .body_scrolling::-webkit-scrollbar {
    width: 4px;
    background-color: transparent;
  }

  .body_scrolling::-webkit-scrollbar-thumb {
    border-radius: 50px;
    background-color: #dcdcdc;
  }

  .body_scrolling::-webkit-scrollbar-track {
    border-radius: 50px;
    background-color: transparent;
  }
}

@media screen and (max-width: 992px) {
  .modal_dialog {
    max-height: unset;
    height: 100vh !important;
  }

  .modal_content {
    border-radius: 0;
    height: 100%;
  }

  .modal_content :global(.modal-body),
  .modal_body {
    height: 100vh;
    max-height: unset;
  }
}
