.nav_and_search {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 3;
}

.nav_and_search:global(.sticky) {
    border-bottom: 1px solid #eee !important;
}

.nav_and_search:global(.sticky) :global(select.form-select), 
.nav_and_search:global(.sticky) :global(.search-input) {
    padding: .5rem !important;
    border: none !important;
}

.logo {
    position: absolute;
    top: 20%;
    left: 30px;
    height: 100%;
    display: none;
    opacity: 0;
    transform: translateY(-100%);
    animation: showLogo ease .5s forwards;
}

.logo img {
    height: 60%;
}

@keyframes showLogo {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
.nav_and_search:global(.sticky) .logo {
    display: block;
}

@media screen and (max-width: 992px) {
    .logo {display: none !important;}
}