.nav_button {
  cursor: pointer;
  color: rgb(131, 131, 131);
  transition: all ease 0.3s;
}

.nav_button:not(.filled):hover {
  background-color: var(--bs-gray-100);
}

.filled {
  background: var(--verde);
  color: white;
}

@media screen and (min-width: 993px) {
  .nav_button_mobile {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .nav_button_mobile {
    position: fixed;
    z-index: 2;
    bottom: 50px;
    left: 0;
    width: 100%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    background: var(--verde);
    color: white;
    transform: translateY(100%);
  }

  .filled {
    transform: translateY(0);
  }
}
